import React from 'react';
import { Section, Layout } from 'components';
import inst1 from '../images/cafe_instruments/inst_1.jpg';
import inst2 from 'images/cafe_instruments/inst_2.jpg';
import { cafeInstruments } from '../containers/cafesori/content';

const CafeInstruments = () => {
  return (
    <Layout>
      <div className='uk-padding-large'></div>
      <Section>
        <div className='uk-grid uk-grid-match'>
          <div className='uk-width-1-2@m'>
            <img src={cafeInstruments.img} width='' height='' alt='' />
          </div>
          <div className='uk-width-1-2@m'>
            <div className='uk-flex uk-flex-middle'>
              <div className='uk-flex uk-flex-column'>
                <div className='uk-h2 uk-text-bold'>음향장비 소개</div>
                <div className='uk-h5'>
                  "카페소리의 자랑은 유럽을 대표하는 빈티지 탄노이 Turnberry
                  스피커가 있으며 Mclntosh와 Jadis 진공관 앰프가 각각의 특징에
                  맞는 탄노이 스피커와 연결되어 듣는 이로 하여금 편안하게 음악을
                  감상하게 합니다.
                  <br />
                  <br />
                  TANNOY는 영국을 대표하는 스피커 브랜드로 1926년 탄생했으며,
                  1946년에 처음으로 듀얼 콘센트릭 기술을 개발해 고역과 저역을
                  동축에 탑재한 새로운 유닛을 개발해 인위적이지 않으면서
                  자연스럽고 투명한 음색으로 정평이 나있습니다. 1세기에 가까운
                  세월 동안 전 세계에서 가장 인기 있는 전통 스피커 브랜드로 그
                  중 최상품은 TANNOY 80주년을 기념하여 출시한 WESTMINSTER ROYAL
                  SE 모델입니다.
                  <br />
                  <br />
                  ATC SCM 20SL 스피커는 오랜 기간 꾸준한 사랑을 받아오고 있는
                  ATC의 대표적인 스피커입니다. 자체 기술력을 바탕으로 하여
                  견고하게 제작된 유닛을 통해 깊이 있고 정확하며 다이나믹하게
                  저역, 중역, 고역의 음을 최적으로 재생하며, 제품의 사이즈를
                  뛰어 넘는 훌륭한 질감의 사운드를 제공합니다.
                  <br />
                  <br />
                  메킨토시 MC-275는 60년 넘게 사랑 받고 있는 진공관식 파워앰프로
                  50주년 기념 제품이 있습니다. 75W/CH라는 엄청난 대출력 특성,
                  11개의 풍족한 진공관의 배열, 고급스러운 크롬 마감의 섀시,
                  세개의 중후한 사각 트랜스포머를 보유한 파워앰프 MC-275는
                  지금까지도 진공관식 파워앰프의 황제로 군림하고 있으며,
                  중후하고 힘차며 대출력을 기반으로 한 다이내믹한 에너지감은
                  매킨토시 튜브 사운드의 가치를 대변하는 완성품입니다.
                  <br />
                  <br />
                  Jadis DA88S는 프랑스의 명문 자디스에서 선보인 인티앰프로 한때
                  풍미했던 오케스트라라는 인티앰프를 보다 발전시킨 제품입니다.
                  이후, 이 제품은 여러 버전을 거쳐, 현재 다양한 인티앰프군을
                  만드는 효시가 된 제품입니다. DA88S의 기본 컨셉은 JA88이라는
                  모노 블록 파원앰프를 하나의 섀시에 담고 여기에 프리단을 더한
                  것이라고 볼 수 있습니다. 프랑스인들의 시각적 디자인, 탁월한
                  예술적 감각, 사운드 자체에 대한 독특한 해석을 자디스 진공관
                  앰프를 통해서 만나 볼 수 있으며 현대와 과거를 아우르는
                  사운드를 선사합니다.
                  <br />
                  <br />
                  Krell KSA?150 은 고성능, 대출력 앰프로 유명한 하이엔드기기를
                  생산하는 미국 3대 최고급 오디오 메이커 회사인 Krell사에서
                  출시한 제품으로 A급 방식의 150W 출력의 대형 파워앰프로 출시
                  이후 폭발적인 인기와 성능을 인정받은 모델입니다.
                  <br />
                  <br />
                  McINTOSH MCD-301은 안정성과 정숙성이 뛰어난 SACD/CD 메커니즘,
                  신호 판독 정밀도가 뛰어난 서보회로로써 레이저 포커싱 개선, 저
                  왜곡 멀티비트 D/A 컨버터를 채용, 아날로그 회로를 향상시켜
                  오디오 기능이 더욱 향상된 제품입니다.
                  <br />
                  <br />
                  MARANTZ SA-11S2는 하이파이 오디오분야에서 가장 널리 알려지고
                  SACD 플레이어를 가장 주력으로 해왔다고 할 수 있는 마란츠에서
                  SA-11S1보다 음질을 더욱 개선하여 출시한 제품으로 마란츠의
                  레퍼런스 라인인 11S의 두번째 작품입니다.
                  <br />
                  <br />
                  Powertek western은 자동전압 조절 장치입니다. 오디오 기기에
                  공급되는 전압의 오차는 음질에 영향을 주게 되는데, 전압이
                  기준치에 비해 높으면 다소 거친 음색이, 또 낮으면 맥이 풀린
                  소리가 나오게 됩니다. Powertek western은 5kw의 충분한 용량에
                  우수한 필터를 채용하여 음향기기에 전압을 항상 일정한 수치로
                  유지하게 하여 음을 질적으로 향상 시키는 장치입니다.",
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='uk-flex uk-margin-medium'>
          <img className='uk-width-1-2' src={inst1} alt='' />
          <img className='uk-width-1-2' src={inst2} alt='' />
        </div>
      </Section>
    </Layout>
  );
};

export default CafeInstruments;
